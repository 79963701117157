.swiper {
    background: #eee;
    height: fit-content;
    padding: 0;
  }

.swiper-wrapper {
    align-items: center;
    padding: 0;
}

/* update this apply to mobile, but desktop should be bigger */
.swiper-slide img {
    width: 100%;
    max-height: 250px;
    object-fit: cover;
    padding: 0;
}

.swiper .swiper-pagination-fraction {
    color: white;                           /* White color for the numbers */
    background-color: rgba(0, 0, 0, 0.45);   /* Semi-transparent black background */
    border-radius: 3px;                    
    padding: 2px 5px; 
    width: 40px;
    font-size: 13px;
}

.swiper .swiper-pagination-total{
    margin-right: 9px;
}